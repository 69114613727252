import React from "react"
import Typography from '@material-ui/core/Typography';
import { Paper, Button } from "@material-ui/core";

export default function ReviewUs() {
    return (
        <div class="review-us-container">
            <img src="/img/logo.png" alt="" />
            <Paper elevation={3} className="review-us-paper">
                <Typography variant="h3">We appreciate your feedback!</Typography>
                <Typography>
                    We value your business and appreciate you as a customer. Being a small business, we rely on customers like you to spread the word about our business and the quality service you receive from our Technicians.
                </Typography>
                <Typography>
                    If you can spare a few minutes of your time, we'd appreciate you giving us some feedback, so that we can make our service better for you and other customers.
                </Typography>
                <Button variant="contained" color="primary" href="https://search.google.com/local/writereview?placeid=ChIJHwD0b7W2wogRUpjRcPLZ9e8">
                    Leave a Review
                </Button>
            </Paper>
        </div>
    )
}