import React, { useState } from "react"
import { Typography, Paper, TextField, Button, Snackbar } from "@material-ui/core"
import * as serviceWorker from './serviceWorker';

export default function SendText({ authKey }) {
    serviceWorker.register();
    const [phoneNum, setPhoneNum] = useState("")
    const [name, setName] = useState("")
    const [requesting, setRequesting] = useState(false)
    const [notif, setNotif] = useState(null)

    const sendText = () => {
        setRequesting(true)
        const data = {
            to: phoneNum,
            name: name,
            messageSelection: "REPAIR_THANK_YOU",
            authToken: authKey
        }
        fetch('https://review.a-qualitypools.net/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                setNotif(data.message)
                setPhoneNum("")
                setName("")
                setRequesting(false)
            })
            .catch((error) => {
                setNotif('Error: ' + error)
                setRequesting(false)
            })
    }

    return (
        <>
            <div class="review-us-container">
                <img src="/img/logo.png" alt="" />
                <Paper elevation={3} className="review-us-paper">
                    <Typography variant="h3">Send Text</Typography>
                    <TextField label="Phone Number" variant="outlined" type="tel" value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} />
                    <TextField label="Customer Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                    <Button disabled={requesting} variant="contained" color="primary" onClick={sendText}>Send Text</Button>
                </Paper>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={notif != null}
                autoHideDuration={6000}
                onClose={() => setNotif(null)}
                message={notif}
            />
        </>
    )
}