import React from 'react';
import ReviewUs from './ReviewUs';
import { StylesProvider } from '@material-ui/core';
import SendText from './SendText';

function App() {

  const urlParams = new URLSearchParams(window.location.search);
  let key = urlParams.get('key');
  if (key === "clear") {
    localStorage.clear()
    key = null  
  }
  if (key == null) {
    key = localStorage.getItem('key')
  }
  let Page = ReviewUs
  if (key != null) {
    Page = () => {
      return (
        <SendText authKey={key} />
      )
    }
    localStorage.setItem('key', key);
  }

  return (
    <StylesProvider injectFirst>
      <Page />
    </StylesProvider>
  );
}

export default App;
